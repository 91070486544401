jQuery( 'body' ).ready( function ( $ ) {

  $( '.play-video' ).on( 'click', function () {
    let video = document.getElementById( 'video-header-home' );
    video.play();
  } );

  $( '#content-header-slider' ).slick( {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    prevArrow: $( '.cafeto-header-icon-prev' ),
    nextArrow: $( '.cafeto-header-icon-next' ),
  } );

  if ( $( '.slick-slider-clients' ).length > 0 ) {
    $( '.slick-slider-clients' ).slick( {
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 783,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 496,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    } );
  }

  if ( $( '.slick-slider-clients-down' ).length > 0 ) {
    $( '.slick-slider-clients-down' ).slick( {
      centerMode: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 783,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 496,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    } );
  }

  if ( $( '.slick-slider-companies' ).length > 0 ) {
    $( '.slick-slider-companies' ).slick( {
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 2,
      prevArrow: $( '.testimony-icon-prev' ),
      nextArrow: $( '.testimony-icon-next' ),
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            dots: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1
          },
        },
      ],
    } );
  }

  $( ".slick-slider-awards" ).slick( {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 4,
    slidesPerRow: 1,
    vertical: false,
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2,
          slidesPerRow: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          centerPadding: 10,
        },
      },
    ],
  } );
} );
